@import '~arduino-sass/src/breakpoints';
@import '~normalize.css';
@import 'variables';
@import 'mixins';
@import 'content';

html {
  box-sizing: border-box;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 16px;
  letter-spacing: 0.3px;
  min-width: 320px;

  @include breakpoint($breakpoint-xxl) {
    font-size: 14px;
  }
  color: $onyx;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 15px;

  &:last-child:not(:first-child) {
    margin: 0;
  }
}

.disable-select {
  user-select: none;
}

hr {
  background-color: $silver;
  border: none;
  height: 1px;
  padding: 0;
  min-width: 100%;
}

input:not([type='checkbox']):not([type='radio']),
button {
  appearance: none;
  background: transparent;
  box-shadow: none;
}

button:focus {
  outline: none;
}

.center {
  text-align: center;
}

a {
  color: $teal2;
  text-decoration: none;

  &:hover {
    color: $teal5;
  }
}

a[role='button'] {
  border-bottom: none;
}

.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#root {
  position: relative;
  flex: 1;
  margin-bottom: 3px;
}

.with-buttons {
  #ino-header-new .app-menu-main {
    margin-left: -100px;
  }
}
