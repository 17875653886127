@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/buttons';
@import 'variables';

$transition-duration: 0.2s;

%bullet {
  width: 12px;
  height: 12px;
  background: $white;
  border: 1px solid #7f8c8d;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }

  @include breakpoint($breakpoint-sm) {
    // margin: 0 3px;
  }

  &::before {
    background: #2c353a;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 50%;
  }
}

@mixin common-button {
  display: flex;
  align-items: center;

  img {
    margin-left: 5px;
  }

  &.disabled {
    background-color: $silver;
    border-color: $silver;
    pointer-events: none;
  }

  &.loading {
    background-color: $teal0;
    border-color: $teal0;
    cursor: wait !important;
  }
}

%primary_button {
  @include ardu-store-button($teal3, $white);
  @include common-button();
}

%secondary_button {
  @include ardu-store-button($teal3, $white);
  @include common-button();
}

%tertiary_button {
  @extend %text-button;
  @include common-button();
  color: $teal3;
}

.default-button {
  @extend %primary_button;

  display: inline-block;

  &:hover {
    color: $white;
  }
}

.content-page {
  padding: 50px 0;

  &.description-media {
    padding: 0;

    @include breakpoint($breakpoint-md) {
      padding: 0 0 40px;
    }
  }

  &.just-description {
    padding: 40px 0;
  }

  .main-container {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;

    @include breakpoint($breakpoint-lg) {
      padding: 0 55px;
    }

    @include breakpoint($breakpoint-md) {
      padding: 0 35px;
    }

    @include breakpoint($breakpoint-sm) {
      padding: 0 15px;
    }
  }

  &.slide {
    margin: 0;

    @include breakpoint($breakpoint-md) {
      padding-top: 50px;
    }

    &.slider--full-width-video {
      @include breakpoint($breakpoint-md) {
        padding-top: 0;
        color: $black;
        background-color: transparent;
      }
    }

    @include breakpoint($breakpoint-xs) {
      padding-top: 20px;
      align-items: flex-start;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    .right-side & {
      flex-direction: row-reverse;
    }

    @include breakpoint($breakpoint-sm) {
      flex-direction: column;

      .right-side & {
        flex-direction: column;
      }

      .text-on-top & {
        flex-direction: column-reverse;
      }
    }
  }

  &__image {
    width: 50%;
    position: relative;

    > img {
      width: 100%;
    }

    @include breakpoint($breakpoint-md) {
      padding: 0;
      width: 100%;
      display: block;
      margin-bottom: 25px;

      > img {
        margin: 0 auto;
      }
    }
  }

  &__video {
    width: 50%;

    > div {
      width: auto;
    }

    @include breakpoint($breakpoint-md) {
      width: 100%;
      margin-bottom: 25px;
      padding: 0;

      > div {
        margin: 0 auto;
        max-height: 380px;
      }

      .text-on-top & {
        margin-bottom: 0;
      }
    }

    @include breakpoint($breakpoint-xs) {
      > div {
        max-height: 280px;
      }
    }
  }

  &__box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__text-box {
    max-width: 980px;
    margin: 0 auto;
    width: 50%;

    .carousel-content &,
    .just-description &,
    .files-content &,
    .two-column &,
    .markdown & {
      text-align: center;
      width: 100%;
    }

    @include breakpoint($breakpoint-md) {
      width: 100%;

      .text-on-top & {
        margin-bottom: 50px;
      }
    }

    @include breakpoint($breakpoint-xs) {
      padding: 0 10px;
    }
  }

  &__tag {
    font-family: $font-roboto;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
  }

  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 25px;

    .content-page--big-title & {
      font-size: 48px;
      font-weight: normal;
    }

    @include breakpoint($breakpoint-xxl) {
      font-size: 24px;

      .content-page--big-title & {
        font-size: 40px;
      }
    }

    p {
      margin: 0;
    }

    .videos-content &,
    .cards-content & {
      margin-bottom: 25px;
    }
  }

  &__description {
    padding: 0 30px;
    max-width: 980px;
    margin: 0 auto 35px;

    .cards-content &,
    .instagram-content & {
      text-align: center;
      width: 100%;
    }

    .description & {
      margin-bottom: 30px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 14px;
      line-height: 19px;
      padding: 0 10px;
    }
  }

  &__text-content {
    text-align: left;
  }

  &__actions {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;

    .card-item & {
      margin: 20px 0;
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;

      .cards-content__icons & {
        justify-content: space-around;
      }
    }

    a,
    span.content-page__button {
      margin-right: 16px;
      @include breakpoint($breakpoint-sm) {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .image-exist &,
    .video-exist & {
      justify-content: flex-start;
    }

    .slide & {
      justify-content: flex-start;
    }

    .slide.centered & {
      justify-content: center;
    }

    @include breakpoint($breakpoint-lg) {
      .image-exist &,
      .video-exist & {
        justify-content: center;
      }
    }
  }

  &__link-button {
    @extend %tertiary_button;
  }

  &__button {
    &.primary {
      @extend %primary_button;
      font-weight: 700;
    }

    &.secondary {
      @extend %secondary_button;
      font-weight: 700;
    }

    &.tertiary {
      @extend %tertiary_button;
      font-weight: 700;
    }

    &.primary-white {
      @extend %primary_button;
      font-weight: 700;

      background-color: $white;
      border-color: $white;
      color: $jet;
    }

    &.secondary-white {
      @extend %secondary_button;
      font-weight: 700;

      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }

  &.cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
  }

  &.files-content {
    .main-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .content-page__box {
      margin-bottom: 50px;
    }
  }
}

.slide {
  background-color: transparent;
  display: flex;
  align-items: center;
  margin: 0 0 100px;
  font-family: $font-roboto;
  position: relative;

  &.slider--with-video {
    @include breakpoint($breakpoint-md) {
      .page-content & {
        height: auto;
      }
    }
  }

  &__video {
    position: relative;

    .slider--with-content & {
      max-width: calc(100% - 650px);

      @include breakpoint($breakpoint-md) {
        max-width: 100%;
      }
    }

    .slider--full-width-video & {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      max-width: initial;
      overflow: hidden;

      @include breakpoint($breakpoint-md) {
        position: relative;
        margin-bottom: 40px;
      }
    }

    video {
      max-width: 100%;
      .slider--full-width-video & {
        margin: 0 auto;
        display: block;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__nav {
    position: absolute;
    z-index: 2;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;

    &-item {
      @extend %bullet;

      &--active {
        &::before {
          content: '';
        }
      }
    }
  }

  &__content {
    max-width: 650px;
    position: relative;
    z-index: 1;
    opacity: 0.2;

    .centered & {
      text-align: center;
    } 

    @include breakpoint($breakpoint-md) {
      width: 100%;
      max-width: none;
    }
  }

  &__box {
    margin: 0 auto;
    max-width: 1220px;
    width: 100%;
    display: flex;
    justify-content: inherit;
    padding: 0 20px;
    flex-direction: inherit;
    align-items: center;

    @include breakpoint($breakpoint-md) {
      flex-direction: column;
      align-items: flex-start;

      .slide__content {
        order: 2;
        width: 50%;
      }
    }

    .slider--full-width-video & {
      @include breakpoint($breakpoint-md) {
      }
    }

    @include breakpoint($breakpoint-xs) {
      padding: 0 15px;
      align-items: center;

      .slide__content {
        width: 100%;
      }
    }

    .centered & {
      justify-content: center;
    }
  }

  &__tag {
    font-weight: 700;
    line-height: 1;
    margin-bottom: 20px;
    font-family: $font-roboto;

    @include breakpoint($breakpoint-xs) {
      font-size: 12px;
    }
  }

  &__title {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: bold;


    @include breakpoint($breakpoint-xxl) {
      font-size: 40px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 28px;
      letter-spacing: 0;
    }

    .smaller & {
      font-size: 28px;

      @include breakpoint($breakpoint-xxl) {
        font-size: 24px;
      }
    }

  }

  &__description {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.7;

    @include breakpoint($breakpoint-md) {
      font-size: 16px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 14px;
      line-height: 1.5;
    }

    .smaller & {
      font-size: 16px;

      @include breakpoint($breakpoint-md) {
        font-size: 14px;
      }
    }

  }

  &__note {
    font-family: $font-sans;
    font-size: 14px;

    @include breakpoint($breakpoint-md) {
      font-size: 12px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 10px;
    }
  }
}

.file-list {
  max-width: 1000px;
  border-radius: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $white;

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid $smoke;

    &:not(:first-child) {
      margin-top: -1px;
      border-top-color: transparent;
    }

    &:hover {
      border-color: #0ca1a6;
    }
  }

  .file-item {
    height: inherit;
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $black;

    &:hover {
      text-decoration: none;
      border-bottom: none;
      cursor: pointer;
      background-color: $teal0;
      color: $black;

      .file-item__link {
        color: $white;
      }

      .file-item__action {
        border-color: #0ca1a6;
      }
    }

    &__title {
      height: 100%;
      padding: 15px 20px;
      flex-grow: 1;
      font-size: 18px;
      display: flex;
      align-items: center;
      overflow-wrap: break-word;
      word-wrap: break-word;

      &:hover {
        cursor: pointer;
      }

      @include breakpoint($breakpoint-lg) {
        padding: 15px 20px;
        font-size: 16px;
      }

      @include breakpoint($breakpoint-xs) {
        padding: 15px;
      }
    }

    &__action {
      height: 100%;
      min-width: 80px;
      max-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $smoke;
    }

    &__link {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-transform: uppercase;
      color: $teal2;

      &:hover {
        border-bottom: none;
      }

      svg,
      img {
        @include size(20px);
      }
    }
  }

  &__more-button {
    position: relative;

    .content-page & {
      padding-left: 17px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      width: 0;
      height: 11px;
      left: 5px;
      border-left: 1.5px solid;
    }

    &::after {
      width: 11px;
      height: 0;
      border-top: 1.5px solid;
      left: 0;
      top: 50%;
    }
  }
}

.card-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;

  &__row {
    width: calc(100% + 10px);
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .cards-content__large-icons &,
    .cards-content__icons & {
      justify-content: center;
    }

    @include breakpoint($breakpoint-xs) {
      margin: 0;
      width: 100%;
    }
  }

  &__item {
    position: relative;
    height: inherit;
    width: 100%;
    margin-bottom: 30px;

    @include breakpoint($breakpoint-sm, 'min') {
      padding: 0 15px;
    }
  }
}

.description-media {
  .content-page__title {
    line-height: 1.7;
    font-family: $font-sans;
    margin-bottom: 15px;
  }

  &:not(.just-description) {
    .content-page__content {
      @include breakpoint($breakpoint-md) {
        flex-direction: column;
      }
    }

    .content-page__title {
      text-align: left;

      @include breakpoint($breakpoint-xxl) {
        font-size: 25px;
      }

      @include breakpoint($breakpoint-lg) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    .content-page__tag {
      font-weight: 700;
      line-height: 1;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid $black;
      font-family: $font-roboto;
      text-align: left;
    }

    .content-page__description {
      line-height: 1.7;
      max-width: none;
      width: 100%;
      padding: 0;

      @include breakpoint($breakpoint-xxl) {
        font-size: 14px;
      }
    }

    .content-page__text-box {
      padding: 15px 20px;

      @include breakpoint($breakpoint-md, 'min') {
        padding: 15px 30px;
      }

      @include breakpoint($breakpoint-xl, 'min') {
        padding: 30px 60px;
      }

      @include breakpoint($breakpoint-xxl, 'min') {
        padding: 30px 100px;
      }
    }

    .main-container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0;
    }
  }
}

.two-column {
  .content-page__text-box {
    max-width: 1130px;
  }

  &__list {
    display: flex;
    text-align: left;
    margin-bottom: 50px;
    padding-top: 50px;

    @include breakpoint($breakpoint-lg) {
      margin-bottom: 30px;
      padding-top: 30px;
    }

    @include breakpoint($breakpoint-sm) {
      flex-direction: column;
    }
  }

  &__item {
    width: 50%;
    padding: 0 70px;
    margin-bottom: 50px;

    @include breakpoint($breakpoint-lg) {
      padding: 0 50px;
      margin-bottom: 30px;
    }

    @include breakpoint($breakpoint-md) {
      padding: 0 30px;
      margin-bottom: 20px;
    }

    @include breakpoint($breakpoint-sm) {
      width: 100%;
      padding: 0 0;
      margin-bottom: 20px;
    }

    &.markdown {
      h4 {
        padding-top: 20px;
      }

      p {
        margin-bottom: 18px;
      }
    }

    @include breakpoint($breakpoint-sm, 'min') {
      &:nth-child(1) {
        padding-left: 0;
        position: relative;

        &::after {
          content: '';
          width: 1px;
          background-color: $silver;
          height: 80%;
          position: absolute;
          top: 10%;
          right: 1px;
        }
      }

      &:nth-child(2) {
        padding-right: 0;
      }
    }
  }
}

.card-item__image {
  .carousel__thumbnails &,
  .carousel__thumbnails-light & {
    height: 190px;
  }

  .cards-content__square &,
  .cards-content__default &,
  .cards-content__half &,
  .cards-content__light &,
  .carousel__thumbnails &,
  .carousel__thumbnails-light & {
    > div,
    picture {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      object-fit: cover;
      height: auto;
      width: 100%;
      max-height: 100%;
    }
  }

  .cards-content__icons & {
    img {
      width: auto !important;

      @include breakpoint($breakpoint-md, 'min') {
        max-width: 90px;
      }
    }
  }

  .cards-content__large-icons & {
    img {
      width: auto !important;
      max-width: 170px;
    }
  }
}

.content-page__image,
.content-page__video {
  max-height: 500px;

  @include breakpoint($breakpoint-md) {
    height: auto;
  }

  > div:not(.slide__nav),
  picture {
    width: 100%;
    height: 100%;
    display: flex;

    @include breakpoint($breakpoint-md) {
      width: 100% !important;
      .internal-video-exist & {
        width: auto !important;
      }
    }
  }

  img {
    object-fit: cover;
    height: auto;
    width: 100%;
  }
}

.play-button {
  width: 68px;
  height: 48px;

  .card-item__video & {
    width: 50px;
    height: 40px;
  }
}

.content-page__image,
.content-page__video {
  .small-media-description & {
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint($breakpoint-lg) {
      padding: 20px;
    }

    @include breakpoint($breakpoint-xs) {
      padding: 0;
    }
  }
}

.markdown-content {
  .content-page__text-box {
    width: 100%;
  }
}

.map-content-wrapper {

  padding-top: 0;
  padding-bottom: 40px;

  .content-page__box {
    width: 100%;
  }

  .map-container {
    z-index: 0;
    position: relative;
    width: 100%;
  }
}

.content-page__markdown {
  text-align: left;
}

hr {
  margin: 50px 0;
  background-color: #c9d2d2;
}

.markdown {
  line-height: 1.7;
  max-width: 860px;
  margin: 0 auto;

  .note {
    background: #f4f4f4;
    border-radius: 3px;
    padding: 12px 20px;
  }

  iframe {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    display: block;
    height: 390px;
  }

  p {
    margin-bottom: 35px;
  }

  img {
    margin: 20px auto;
    display: block;
  }

  blockquote {
    font-style: italic;
    border-left: 3px solid $gold;
    margin-left: 0;
    padding-left: 30px;
  }

  code {
    background: #efefef;
    border-radius: 2px;
    padding: 2px 4px;
  }

  ul {
    padding: 0;
    margin: 0 0 40px;
    list-style: none;

    ul {
      margin: 5px 0 0 5px;
    }

    li {
      margin-bottom: 5px;
      padding-left: 26px;
      position: relative;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 1px;
        background-color: $gold;
        border: 1px solid $gold;
        transform: rotate(-45deg);
        position: absolute;
        left: 0;
        top: 9px;
      }

      li {
        &:before {
          background: none;
        }
      }
    }
  }

  strong em {
    padding: 12px 28px;
    background-color: #f4f4f4;
    display: block;
    font-style: normal;
    font-weight: normal;

    &::before {
      content: 'NOTE: ';
      font-weight: bold;
      font-family: $font-roboto;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 15px;
}

h1 {
  font-size: 48px;
  margin-bottom: 24px;

  @include breakpoint($breakpoint-md) {
    font-size: 40px;
  }
}

h2 {
  font-size: 36px;
  margin-bottom: 16px;

  @include breakpoint($breakpoint-md) {
    font-size: 30px;
  }
}

h3 {
  font-size: 28px;
  margin-bottom: 12px;

  @include breakpoint($breakpoint-md) {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;
  margin-bottom: 18px;

  @include breakpoint($breakpoint-md) {
    font-size: 16px;
  }
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

#root .hljs {
  background: #ecf1f1;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
}

.card-list__item {
  .card-list & {
    .cards-content__default &,
    .cards-content__light & {
      flex: 0 0 33.33%;
      max-width: 33.33%;

      @include breakpoint($breakpoint-md) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .card-list & {
    .cards-content__square & {
      flex: 0 0 50%;
      max-width: 50%;

      @include breakpoint($breakpoint-md) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .card-item {
        background-color: $fog;
      }

      .card-item__content {
        position: relative;
      }

      .card-item__text-content {
        position: absolute;
        padding: 48px;
      }

      .card-item__image {
        margin-bottom: 0;
      }

      .card-item__tag {
        border-bottom: none;
        padding: 0;
      }
    }
  }

  .card-list & {
    .cards-content__half & {
      flex: 0 0 50%;
      max-width: 50%;

      @include breakpoint($breakpoint-md) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .card-list & {
    .cards-content__large-icons &,
    .cards-content__icons & {
      flex: 0 0 25%;
      max-width: 25%;

      @include breakpoint($breakpoint-md) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include breakpoint($breakpoint-xs) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .card-list & {
    &.card-list__item-horizontal {
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;

      .card-item__image {
        &::after {
          background: linear-gradient(270deg, #7fcbcd 10%, rgba(0, 0, 0, 0) 150%);
        }
      }

      &:nth-child(2n) {
        .card-item__content {
          @include breakpoint($breakpoint-sm, 'min') {
            .chess-order & {
              flex-direction: row-reverse;
            }
          }
        }
      }

      .card-item__content {
        @include breakpoint($breakpoint-sm, 'min') {
          flex-direction: row;
        }
      }

      .card-item__image {
        margin-bottom: 0;

        @include breakpoint($breakpoint-sm, 'min') {
          flex: 0 0 50%;
          max-width: 50%;
          height: 375px;
        }
      }

      .card-item__text-content {
        .cards-content__default &,
        .cards-content__half &,
        .cards-content__light &,
        .carousel__thumbnails &,
        .carousel__thumbnails-light & {
          padding: 10px 70px;

          @include breakpoint($breakpoint-xxl) {
            padding: 10px 50px;
          }

          @include breakpoint($breakpoint-sm) {
            padding: 10px 20px;
          }
        }
      }

      .content-page__actions {
        padding: 0;
      }
    }
  }
}

.card-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  transition-duration: $transition-duration;
  transition-property: background-color;

  @include breakpoint($breakpoint-sm, 'min') {
    padding: 8px;

    .cards-content__square & {
      padding: 0;
    }
  }

  &__text-content {
    z-index: 1;
    width: 100%;
  }

  .card-item__image {
    &::after {
      transition-duration: $transition-duration;
      transition-property: opacity;
      background: linear-gradient(360deg, #7fcbcd 10%, rgba(0, 0, 0, 0) 150%);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      content: '';
    }
  }

  .cards-content__half &,
  .cards-content__default &,
  .carousel__thumbnails &,
  .carousel__with-date &,
  .cards-content__light & {
    background: $white;
    border: 1px solid $smoke;
    border-radius: 3px;
  }

  .without-border & {
    border-color: transparent;
    background: none;
  }

  .carousel__thumbnails-light & {
    text-align: center;
  }

  .cards-content__large-icons &,
  .cards-content__icons & {
    text-align: center;
  }

  &:not(.card-item--with-buttons):not(.card-item--with-video) {
    &:hover {
      .cards-content__half &,
      .cards-content__default &,
      .carousel__thumbnails &,
      .cards-content__light &,
      .cards-content__square & {
        background-color: $teal0;

        .card-item__image,
        .card-item__video {
          &::after {
            opacity: 1;
          }
        }

        .card-item__tag {
          color: $black;
          border-bottom-color: rgba($teal2, 0.5);
        }

        .card-item__title {
          color: $black;
        }

        .card-item__description {
          color: $black;
        }
      }
    }
  }

  &__buttons {
    width: 100%;
    padding: 12px 15px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    position: relative;
    align-items: center;
    color: $black;

    .content-page__actions {
      margin: 0;
      padding: 0;
      width: auto;
    }

    &::before {
      border-top: 1px solid $silver;
      content: '';
      position: absolute;
      width: calc(100% + 16px);
      left: 0;
      top: 0;
      margin: 0 -8px;
      padding: 0 8px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint($breakpoint-xs) {
      align-items: flex-start;
    }
  }

  &__video {
    background-size: cover;
    border-radius: 4px;
    display: block;
    opacity: 1;
    position: relative;
    transition-duration: $transition-duration;
    transition-property: opacity;
    margin-bottom: 35px;
    width: 100%;
  }

  &__image {
    background-size: cover;
    border-radius: 4px;
    display: block;
    opacity: 1;
    position: relative;
    transition-duration: $transition-duration;
    transition-property: opacity;
    margin-bottom: 25px;
    text-align: center;
    width: 100%;

    .cards-content__large-icons &,
    .cards-content__icons & {
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      @include position(absolute, 0 0 0 0);

      content: '';
      opacity: 0;
      transition-duration: $transition-duration;
      transition-property: opacity;
    }

    > img {
      .cards-content__square &,
      .cards-content__half &,
      .cards-content__default &,
      .carousel__thumbnails &,
      .carousel__thumbnails-light & {
        width: 100%;
        border-radius: 3px;
      }
    }
  }

  &__text-content {
    .cards-content__half &,
    .cards-content__default:not(.without-border) &,
    .carousel__thumbnails &,
    .carousel__thumbnails-light &,
    .carousel__with-date & {
      padding: 0 15px;
    }

    .cards-content__light & {
      border-top: 1px solid $silver;
      padding: 5px 0 0;
      max-width: 90%;
    }
  }

  &__tag {
    width: 100%;
    padding: 5px 0 10px;
    font-family: $font-roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: $black;
    border-bottom: 1px solid $smoke;
    margin-bottom: 15px;

    @include breakpoint($breakpoint-xs) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  &__title {
    width: 100%;
    font-weight: 700;
    line-height: 1.7;
    margin-bottom: 10px;
    transition-duration: $transition-duration;
    transition-property: color;

    .cards-content__half &,
    .cards-content__default &,
    .cards-content__light &,
    .carousel__thumbnails & {
      font-size: 16px;
    }
    .card-list__item-horizontal & {
      font-size: 28px;
      line-height: 1.4;

      @include breakpoint($breakpoint-xxl) {
        font-size: 24px;
      }
    }

    .cards-content__square & {
      font-size: 36px;
      line-height: 1.4;

      @include breakpoint($breakpoint-xxl) {
        font-size: 32px;
      }
    }

    .carousel__thumbnails-light & {
      font-size: 20px;
      font-weight: normal;
    }

    .carousel__with-date & {
      font-size: 12px;
      margin-bottom: 5px;
      padding-top: 5px;
      font-family: $font-roboto;
    }

    > p {
      margin: 0;
    }
  }

  &__description {
    line-height: 1.7;
    margin-bottom: 15px;
    transition-duration: $transition-duration;
    transition-property: color;

    .cards-content__large-icons &,
    .cards-content__icons &,
    .carousel__thumbnails-light & {
      font-size: 14px;
    }
  }
}

.carousel-content {
  .main-container {
    position: relative;
    padding-bottom: 50px;
  }
}

.carousel {
  box-sizing: border-box;
  position: relative;

  &--plans-prices {
    height: 40px;
    display: none;

    @include breakpoint($breakpoint-lg) {
      display: block;
    }

    .swiper-pagination {
      bottom: 0 !important;
    }
  }

  &.carousel__text {
    max-width: 980px;
    margin: 0 auto;
  }

  &__holder {
    max-width: 1040px;
    margin: 0 auto;

    .carousel__text & {
      max-width: 720px;
    }
  }

  .card-list__item {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .swiper-wrapper {
    height: inherit;
    box-sizing: border-box;
  }

  .swiper-slide {
    padding: 1px;
  }

  .swiper-container {
    display: flex;
  }

  .swiper-pagination {
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -50px;
    flex-wrap: wrap;
  }

  .swiper-pagination-bullet {
    @extend %bullet;
  }

  .swiper-pagination-bullet-active {
    &::before {
      content: '';
    }
  }

  .swiper-button-disabled {
    cursor: disabled;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include size(55px);

    background: $white;
    color: #9e846d;
    border-radius: 50%;
    border: 1px solid $silver;
    color: #2c353a;
    z-index: 2;

    @include breakpoint($breakpoint-md) {
      display: none;
    }

    &:after {
      content: none;
    }

    &::before {
      content: '';

      @include size(16px);

      display: block;
      border-radius: 2px;
      border: solid #2c353a;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .swiper-button-prev {
    &::before {
      border-width: 3px 0 0 3px;
      margin-left: 3px;
    }
  }

  .swiper-button-next {
    &::before {
      border-width: 0 3px 3px 0;
      margin-left: -3px;
    }
  }
}

.carousel__text-item {
  margin: 0 auto;
  text-align: center;

  .bold-style & {
    font-weight: bold;
    font-size: 20px;

    .carousel__text-item-author {
      font-weight: normal;
    }

    .carousel__text-item-note {
      font-size: 14px;
      font-weight: normal;
    }
  }

  &-title {
    font-size: 20px;
    margin-bottom: 18px;
    font-weight: bold;
  }

  &-author {
    font-weight: bold;
  }

  &-note {
    font-size: 12px;
  }
}

.instagram-content {
  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  &__column {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;

    &:nth-child(1) {
      padding-right: 10px;
    }

    &:nth-child(2) {
      padding-left: 10px;
    }

    @include breakpoint($breakpoint-xl) {
      &:nth-child(1) {
        padding-right: 5px;
      }

      &:nth-child(2) {
        padding-left: 5px;
      }
    }

    @include breakpoint($breakpoint-sm) {
      flex: 0 0 100%;
      max-width: 100%;

      &:first-child {
        margin-bottom: 10px;
      }

      &:nth-child(1) {
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-left: 0;
      }
    }
  }
}

.instagram-posts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    height: inherit;
    width: inherit;
    padding-bottom: 20px;

    &:nth-child(2n + 1) {
      padding-right: 10px;
    }

    &:nth-child(2n) {
      padding-left: 10px;
    }

    &:nth-last-child(-n + 2) {
      padding-bottom: 0;
    }

    @include breakpoint($breakpoint-xl) {
      padding-bottom: 10px;

      &:nth-child(2n + 1) {
        padding-right: 5px;
      }

      &:nth-child(2n) {
        padding-left: 5px;
      }
    }
  }
}

.instagram-post {
  display: flex;
  position: relative;
  flex-grow: 1;
  justify-content: center;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  @include breakpoint($breakpoint-xl, 'min') {
    min-height: 190px;
  }

  @include breakpoint($breakpoint-sm) {
    padding: 0 3px;
    margin: 3px 0;
  }

  @include breakpoint($breakpoint-xs) {
    padding: 0 2px;
    margin: 2px 0;
  }

  &__loading {
    left: 50%;
    top: 50%;
    transform: translate(-70%, -70%);

    &::before,
    &::after {
      background: #f7f9f9;
    }
  }

  &__content {
    display: block;
    color: $charcoal;
    width: 100%;

    &:hover {
      border: none;
    }
  }

  &__image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      @include size(100%);

      object-fit: cover;
    }
  }
}

.instagram-content .main-container {
  max-width: 820px;
  margin: 0 auto;
}

.countdown-container {

  .content-page__text-box {
    width: 100%;

    .content-page__title {
      margin-bottom: 10px;

      &.countdown_subtitle {
        margin-bottom: 40px;
        color: $black !important;
      }
    }
  
    .content-page__description {
      max-width: unset;
      text-align: center;
      font-size: 22px;
      margin-top: 50px;
  
      @include breakpoint($breakpoint-xs) {
        font-size: 20px;
        line-height: 22px;
        padding: 0 10px;
      }
    }
  }  
}

.coming-soon {
  text-transform: uppercase;
  font-weight: 700;
  color: #7f8c8d;
}

.cards-content__square .card-item .card-item__image::after {
  background: linear-gradient(0deg, rgba(12, 161, 166, 0.3), rgba(12, 161, 166, 0.3));
}

.word-animation {
  @include breakpoint($breakpoint-xs) {
    display: block;
  }

  b {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    // &:nth-child(2) {
    //   animation-delay: 2.5s;
    // }
    // &:nth-child(3) {
    //   animation-delay: 5s;
    // }
    // &:nth-child(4) {
    //   animation-delay: 7.5s;
    // }
  }
}

@keyframes wordAnimation {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


